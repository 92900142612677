<template>
  <div class="chat-content">
    <dl class="rebot-style">
      <dt>
        <img :src="require(`@/assets/images/chat_image_head_bebot.png`)">
      </dt>
      <dd>
        <p class="headerContent content">{{welcome_msg}}<br />
          <span
            :class="welcome_msg.indexOf('初次')>-1?'manyUnderwritingText':'underwritingText'"
            @click="sendText('核保')"
          >
          </span>
        </p>
      </dd>
    </dl>
    <div
      v-for="(item, index) in list"
      :key="item.id"
    >
      <dl>
        <dd>
        </dd>
      </dl>
      <dl
        v-if="underwriting == 1 && item.content!='核保' || underwriting!=1"
        :class="item.speaker == 0 ? 'rebot-style' : 'custom-style'"
      >
        <dt>
          <img :src="item.speaker==0?require(`@/assets/images/chat_image_head_bebot.png`):userInfo.head_portrait">
        </dt>
        <dd>
          <div
            class="content"
            v-if="underwriting == 1 && item.content!='核保' || underwriting!=1"
          >
            <!-- 右侧回答 -->
            <span
              v-if="item.speaker == 1"
              class="contentText"
              v-html="item.content"
              style="word-break:break-all;"
            ></span>
            <!-- 机器人（核保） -->
            <div
              v-if="item.speaker == 0 && item.content.hebao == 1"
              class="contentText"
              v-html="item.content.answer"
            >
            </div>

            <!-- 展示年龄 -->
            <div
              v-if="item.content.reset_disease == 2"
              style='position: relative;padding-bottom: .4rem;'
            >
              <!-- 只读和填写 -->
              <input
                v-if="index != list.length -1"
                type="number"
                max="100"
                maxlength="3"
                class='inputText'
                placeholder="已经成功输入年龄"
                readonly
              >
              <input
                v-if="index === list.length -1"
                type="number"
                v-model="age"
                class='inputText'
                placeholder="请输入你的年龄"
                @focus="showEndText()"
                @blur='leave()'
                @input="maxValue(age)"
                oninput="if(value.length>3)value=value.slice(0,3)"
                v-number-only
              />
            </div>
            <img
              v-if="age!='' && item.content.reset_disease == 2 && index === list.length -1"
              @click="fulfillText(age,{reset_disease:item.content.reset_disease},index)"
              class='fulfill'
              src="../../../assets/images/fulfill.png"
              alt=""
            >
            <!-- 热门疾病 -->
            <div
              class="hotDisease"
              v-if="item.content.reset_disease == 0 && item.content.hebao == 1"
            >
              <p
                class="diseaseText"
                v-if="item.content.common_disease"
              >请选择疾病（多选）</p>
              <ul
                class="uls"
                v-show="item.content.common_disease"
              >
                <li
                  v-for="(each,index) in item.content.common_disease"
                  :class="each.flag?'diseaseList':'noDiseaseList'"
                  @click="diseaseData(index)"
                  :key="each.diseaseCode"
                >
                  {{each.diseaseDesc}}
                </li>
                <p
                  v-if="item.content.common_disease"
                  class="other"
                  @click="goDiseaseList(item.status)"
                >其他疾病</p>
              </ul>
            </div>
            <!-- v-if="item.content.answer == '' && item.content.hebao == 1" -->
            <img
              v-if="index === list.length -1 && item.content.answer == '' && hotDataList.length>0"
              @click="selectOk"
              class='fulfill'
              src="../../../assets/images/selectedImg.png"
              alt=""
            >

            <div v-if="item.content.hebao_answer">
              <div
                v-for="(msg ,btn) in item.content.hebao_answer"
                :key="btn"
                v-text="btn"
                class="underwriting"
                @click="sendText(btn, {'hebao_answer':msg})"
              ></div>
            </div>
            <div v-if="item.speaker==0&&item.content.recommendProducts.length>0">
              <!-- 根据你的情况，匹配到 <span></span> 款产品
              <img class="underwriting_result" :src="require('@/assets/images/核保结果@2x.png')" /> -->
              <div
                class="underwriting"
                @click="changePage('/recommendProduct/'+item.sentence_id)"
              >查看结果</div>
            </div>
          </div>
        </dd>
      </dl>
      <!-- <tip-bar
        :title="item.content=='核保' ? '已切换至智能核保模式':(item.content=='退出核保'?'已切换至普通对话模式':'')"
        :showType="item.content=='核保'?'start':(item.content=='退出核保'?'end':'')"
        v-if="hideHistoryText!=1 && item.content=='核保' || hideHistoryText!=1 && item.content=='退出核保'"
      ></tip-bar> -->
    </div>
    <footer v-if="flag">
      <p
        class="quit"
        v-if="quit"
        @click="sendText('退出核保', {'reset_disease':-1})"
      ></p>
      <p
        class="line"
        v-if="transferData"
      ></p>
      <p
        class="re"
        v-if="transferData"
        @click="sendText('重新核保', {'reset_disease':1})"
      ></p>
    </footer>
    <van-overlay
      :show="showCode"
      @click="showCode = false"
    >
      <div
        class="wrapper"
        @click.stop
      >
        <div class="codeBox">
          <img
            class="codeImg"
            src="../../../assets/images/QrCodeImg.jpeg"
            alt=""
          >
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import eventBus from "../../../utils/eventBus.js";
import { into_count } from "@/fetch/api/chat.js";
import { mapState, mapMutations } from "vuex";
// import TipBar from "./TipBar.vue";
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    chat_model: {
      type: Number,
      default: 0,
    },
    transferData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      age: "",
      maxNum: 100,
      three: 3,
      text: "",
      hotDisease: [],
      hotDataList: [],
      changDisease: [],
      hotDiseaseIndex: "",
      flag: this.$route.query.underwriting == 1 ? true : false,
      diseaseFlag: false,
      lastText: false,
      underwriting: this.$route.query.underwriting,
      hideHistoryText: this.$route.query.hideHistoryText,
      welcome_msg: "",
      quit: false,
      showKeyboard: true,
      showCode: false,
    };
  },
  directives: {
    numberOnly: {
      bind: function (el) {
        el.handler = function () {
          el.value = el.value.replace(/\D+/, "");
        };
        el.addEventListener("input", el.handler);
      },
      unbind: function (el) {
        el.removeEventListener("input", el.handler);
      },
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  filters: {
    replaceLabel: function (val) {
      if (val && val.indexOf("<br>") > -1) {
        return val.replace(/<br>/g, "\n");
      } else {
        return val;
      }
    },
  },
  components: {
    // TipBar,
  },
  mounted() {
    this.list.forEach((item, num) => {
      if (
        typeof item.content === "object" &&
        item.content.common_disease &&
        num === this.list.length - 1
      ) {
        item.content.common_disease.forEach((each) => {
          if (each.flag && this.hotDataList.length >= 0) {
            if (this.hotDataList.length >= 5) {
              this.$toast("最多添加五项疾病哦!");
              return;
            }
            each.flag = true;
            this.hotDataList.push(each);
          }
        });
      }
    });
    if (localStorage.getItem("hideInput")) {
      this.quit = true;
    }
    if (!localStorage.getItem("hideInput")) {
      this.flag = true;
    }
    this.into_count();
  },
  methods: {
    ...mapMutations(["SET_CHAT_LIST"]),
    inputChange() {
      this.age = this.age.replace(/[^\d]/g, "");
    },
    async into_count() {
      const response = await into_count();
      this.welcome_msg = response.welcome_msg;
    },
    // 疾病选择
    diseaseData(val) {
      this.hotDiseaseIndex = val;
      this.changDisease = this.list.map((item, num) => {
        if (
          typeof item.content === "object" &&
          item.content.common_disease &&
          num === this.list.length - 1
        ) {
          item.content.common_disease.map((each, index) => {
            if (index === val && this.hotDataList.length >= 0 && !each.flag) {
              if (this.hotDataList.length >= 5) {
                this.$toast("最多添加五项疾病哦!");
                return;
              }
              each.flag = true;
              this.hotDataList.push(each);
            } else if (
              index === val &&
              each.flag &&
              this.hotDataList.length >= 1
            ) {
              each.flag = false;
              this.hotDataList = this.hotDataList.filter(
                (e) => e.diseaseCode != each.diseaseCode
              );
            }
            return each;
          });
        }
        return item;
      });
    },
    selectOk() {
      let common_diseaseName = this.hotDataList.map((item) => item.diseaseDesc);
      this.sendText(common_diseaseName.join(","), {
        diseaseCode: this.hotDataList,
      });
    },
    goDiseaseList(status) {
      if (!status) return;
      localStorage.setItem("hideInput", false);
      let hotCode = this.hotDataList.map((item) => item.diseaseCode);
      this.$router.push({
        path: "/diseaseList",
        query: {
          code: this.hotDataList.length > 0 ? hotCode.join(",") : undefined,
        },
      });
    },
    // 点击发送消息
    sendMessage(each) {
      if (each.canClick) {
        if (each.text.indexOf("抱歉，Bebot暂时还无法理解您的问题") > -1) {
          this.showCode = true;
          return;
        }
        this.$emit("sendText", each.text, {});
      }
    },
    //  年龄输入完成
    fulfillText(item, option) {
      this.sendText(item, option);
    },
    maxValue(e) {
      if (e < 0) {
        this.age = 0;
      } else if (e > 100) {
        this.age = 100;
      } else {
        this.age = e;
      }
    },
    showEndText() {
      this.quit = false;
      this.flag = false;
    },
    leave() {
      this.quit = true;
      this.flag = true;
    },
    sendText(item, option = {}) {
      if (item == "核保") {
        this.hotDataList = [];
        this.flag = true;
        this.quit = true;
      }
      this.age = "";
      if (option.reset_disease == 1 || option.reset_disease == -1) {
        this.hotDataList = [];
        if (option.reset_disease == -1) {
          this.flag = false;
          this.lastText = false;
        }
      }
      this.$emit("sendText", item, option);
    },
    changePage(path) {
      this.$router.push(path);
    },
  },
  activated() {
    this.$nextTick(() => {
      let container = document.getElementById("chatView");
      if (container && container.scrollHeight != null) {
        container.scrollTop = container.scrollHeight;
      }
    });
    eventBus.$on(
      "addDisease",
      function (val) {
        this.changDisease = this.list.map((item, num) => {
          if (
            typeof item.content === "object" &&
            item.content.common_disease &&
            num === this.list.length - 1
          ) {
            item.content.common_disease.map((each) => {
              if (val.includes(each.diseaseCode)) {
                each.flag = true;
                this.hotDataList.push(each);
              } else {
                this.hotDataList = this.hotDataList.map((v) => {
                  if (v && val.indexOf(v.diseaseCode) == -1) {
                    v.flag = false;
                  }
                });
              }
              return each;
            });
          }
          return item;
        });
      }.bind(this)
    );
  },
};
</script>

<style lang="scss" scoped>
.history-chat-box {
  .header-nav {
    .van-nav-bar__title {
      color: #fff;
      font-size: 0.43rem;
    }
  }
  .content {
    word-break: break-all;
    white-space: normal;
    position: relative;
  }
}
.ageText {
  font-size: 0.37rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
input::-webkit-input-placeholder {
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  outline: none;
  border: none;
  line-height: 0.58rem;
}
.inputText {
  width: 6rem;
  height: 0.67rem;
  background: rgba(24, 50, 155, 0.2);
  border-radius: 0.33rem;
  outline: none !important;
  outline-color: invert;
  outline-style: none;
  outline-width: 0px;
  border: none;
  border-style: none;
  text-shadow: none;
  box-shadow: none;
  padding-left: 0.27rem;
  margin-top: 0.13rem;
  font-size: 0.37rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.53rem;
}
.fulfill {
  width: 2.56rem;
  height: 0.85rem;
  position: absolute;
  left: 50%;
  bottom: -6%;
  transform: translate(-50%);
  z-index: 2;
}
.contentText {
  position: relative;
  text-align: left;
  min-width: 5.33rem;
  overflow-wrap: break-word;
}
.hotDisease {
  width: 100%;
}
.diseaseText {
  font-size: 0.37rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.uls {
  width: 100%;
  padding: 0.27rem 0 0.4rem 0;
}
.diseaseList,
.noDiseaseList {
  margin: 0 0.27rem 0.2rem 0;
  padding: 0.13rem 0.21rem;
  display: inline-block;
  background: rgba(255, 255, 255, 0.7);
  font-size: 0.29rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2150c8;
  border-radius: 0.1rem;
}
.noDiseaseList {
  background: rgba(24, 50, 155, 0.2);
  color: #fff;
}
.other {
  width: 2.21rem;
  height: 0.64rem;
  border-radius: 0.32rem;
  opacity: 0.7;
  border: 0.03rem solid #ffffff;
  margin: 0 auto;
  text-align: center;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.underwritingText,
.manyUnderwritingText {
  width: 2.43rem;
  height: 0.83rem;
  position: absolute;
  left: 50%;
  bottom: -25%;
  transform: translate(-50%);
  background: url("../../../assets/images/underwritingImg.png") no-repeat;
  background-size: 100% 100%;
}
.manyUnderwritingText {
  position: absolute;
  left: 50%;
  bottom: -14%;
  transform: translate(-50%);
  background: url("../../../assets/images/underwritingImg.png") no-repeat;
  background-size: 100% 100%;
}
.underwriting {
  min-width: 2.346667rem;
  min-height: 0.8rem;
  background: linear-gradient(180deg, #a566ff 0%, #6645ff 100%);
  border-radius: 0.213333rem;
  font-size: 0.346667rem;
  line-height: 0.8rem;
  display: inline-block;
  color: #ffffff;
  text-align: center;
  margin: 0.133333rem;
}
.underwriting_result {
  background-image: url("/src/assets/images/核保结果@2x.png");
  background-size: 4.933333rem 5.706667rem;
  width: 4.933333rem;
  height: 5.706667rem;
}
footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 0.91rem;
  position: fixed;
  bottom: 0.72rem;
  left: 0%;
  .quit {
    width: 2.8rem;
    height: 0.91rem;
    background: url("../../../assets/images/quit.png") no-repeat;
    background-size: 100% 100%;
  }
  .line {
    width: 0.03rem;
    height: 0.59rem;
    background: url("../../../assets/images/line.png") no-repeat;
    background-size: 100% 100%;
    margin: 0 0.37rem 0 0.43rem;
  }
  .re {
    width: 2.8rem;
    height: 0.91rem;
    background: url("../../../assets/images/re.png") no-repeat;
    background-size: 100% 100%;
  }
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.codeBox {
  width: 200px;
  height: 200px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.codeImg {
  width: 180px;
  height: 180px;
  position: relative;
  z-index: 100;
  -webkit-user-select: none;
}
.codeImg:focus {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-modify: read-write-plaintext-only;
}
</style>
